import React from 'react';

import styles from './InspirationCollection.module.scss';

import useGetInspirations from 'apis/inspiration/hooks/useGetInspirations';
import useGetLists from 'apis/list/useGetLists';

import { useOptimizely } from 'utils/optimizely/useOptimizely';

import { useSettings } from 'hooks/useSettings';
import useTranslations from 'hooks/useTranslations';

import Hyperlink from 'skapa/HyperLink';

import FamilyPromoCard from 'components/FamilyPromoCard';
import Link from 'components/Link';

const InspirationCollection = () => {
    const { browseProductsUrl, inspirationLink } = useSettings();
    const { translate, translateReplace } = useTranslations();
    const { trackEvent } = useOptimizely();
    const { lists } = useGetLists();
    const { inspirations } = useGetInspirations();

    const isEmpty = inspirations?.length === 0;

    if (!inspirations || isEmpty) {
        return null;
    }

    const hasMoreThanTwoImages = inspirations.length > 2;

    return (
        <div className={styles.inspirationCollection}>
            <header className={styles.header}>
                <Link href="inspiration">
                    <div
                        className={styles.name}
                        onClick={() => {
                            trackEvent('favourites_click_inspiration');
                        }}
                    >
                        {translate('Inspiration')}
                    </div>
                </Link>
                <div className={styles.imagesCount}>
                    {isEmpty
                        ? translate('Empty inspiration collection')
                        : translateReplace('x images', {
                              quantity: String(inspirations.length),
                          })}
                </div>
            </header>
            {isEmpty && (
                <div
                    className={styles.imagesEmpty}
                    data-testid="inspiration-images"
                >
                    <div className={styles.imagesEmptyContent}>
                        <p>{translate('No favourite images saved yet?')}</p>
                        <Hyperlink
                            key="link"
                            text={translate('Get Inspired')}
                            url={inspirationLink || browseProductsUrl}
                        />
                    </div>
                </div>
            )}
            {!isEmpty && (
                <Link href="inspiration" data-testid="inspiration-images">
                    <div
                        className={`${styles.images} ${
                            hasMoreThanTwoImages
                                ? styles.withMoreThanTwoImages
                                : styles.withUpToTwoImages
                        }`}
                        onClick={() => {
                            trackEvent('favourites_click_inspiration');
                        }}
                    >
                        {inspirations.slice(0, 5).map(({ id, slug, url }) => (
                            <div key={id} className={styles.imageWrapper}>
                                <img
                                    className={styles.image}
                                    src={`${url}?f=xxxs`}
                                    alt={slug}
                                />
                            </div>
                        ))}
                    </div>
                </Link>
            )}
            {lists?.length === 0 && <FamilyPromoCard showSkeleton={false} />}
        </div>
    );
};

export default InspirationCollection;
