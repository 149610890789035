import React, { useState } from 'react';

import styles from './LandingPage.module.scss';
import SelectListItem from './SelectListItem';
import SelectListItemSkeleton from './SelectListItem/SelectListItemSkeleton';
import { H1, P } from '@components/Skeleton';

import useGetInspirations from 'apis/inspiration/hooks/useGetInspirations';
import { useSetSelected } from 'apis/list/operations';
import { List } from 'apis/list/reactQueryHooks';
import useGetLists, { Lists } from 'apis/list/useGetLists';

import { useSettings } from 'hooks/useSettings';
import useTranslations from 'hooks/useTranslations';

import AddList from 'components/AddList';
import EmptyLandingPage from 'components/EmptyLandingPage';
import FamilyPromoCard from 'components/FamilyPromoCard';
import Feeds from 'components/Feeds';
import InspirationCollection from 'components/InspirationCollection';
import ListMenuModal from 'components/Modals/ListMenu';
import LoginPromo from 'components/Modals/LoginPromo';

const LandingPage = () => {
    const { showInspirations } = useSettings();
    const { translate } = useTranslations();
    const { lists, isLoading } = useGetLists();
    const [listMenu, setListMenu] = useState<undefined | Lists[number]>();
    const { setSelected } = useSetSelected();

    const sortLists = () => {
        if (!lists) {
            return [];
        }
        const selectedItem = lists.filter(l => l.isSelected);
        const sortedItems = [...lists]
            .sort((a, b) => (a.updated < b.updated ? 1 : -1))
            .filter(l => !l.isSelected);
        return [...selectedItem, ...sortedItems];
    };
    const sortedLists = sortLists();
    const { inspirations } = useGetInspirations();

    if (showInspirations) {
        if (sortedLists?.length === 0 && inspirations?.length === 0) {
            return <EmptyLandingPage showSkeleton={isLoading} />;
        }
    } else if (sortedLists?.length === 0) {
        return <EmptyLandingPage showSkeleton={isLoading} />;
    }

    const getListMenuTitle = () => {
        if (listMenu) {
            return listMenu.name === ''
                ? translate('Default list name')
                : listMenu.name;
        }
        return '';
    };

    return (
        <div>
            <header>
                {showInspirations ? (
                    <>
                        <H1
                            width={275}
                            className={styles.h1}
                            showSkeleton={isLoading}
                        >
                            {translate('Your favourites')}
                        </H1>
                        <P showSkeleton={isLoading}>
                            {translate('Favourites description')}
                        </P>
                    </>
                ) : (
                    <H1
                        width={275}
                        className={styles.h1}
                        showSkeleton={isLoading}
                    >
                        {translate('Your wish lists')}
                    </H1>
                )}
                <LoginPromo showSkeleton={isLoading} />
            </header>
            <InspirationCollection />
            <div data-testid="list-of-lists">
                {isLoading ? (
                    <>
                        <SelectListItemSkeleton />
                        <FamilyPromoCard showSkeleton={isLoading} />
                    </>
                ) : (
                    sortedLists.map((l, i) => (
                        <React.Fragment key={l.listId}>
                            <SelectListItem
                                list={l}
                                onSelect={id => {
                                    if (!l.isSelected) {
                                        return setSelected({ listId: id });
                                    }
                                    return Promise.resolve();
                                }}
                                isPendingRemoval={false}
                                openMenu={() => setListMenu(l)}
                            />
                            {i === 0 && (
                                <FamilyPromoCard showSkeleton={isLoading} />
                            )}
                        </React.Fragment>
                    ))
                )}
            </div>
            <ListMenuModal
                title={getListMenuTitle()}
                visible={!!listMenu}
                onClose={() => setListMenu(undefined)}
                list={listMenu as List}
                isLandingPage
            />
            <AddList showSkeleton={isLoading} />
            <Feeds />
        </div>
    );
};
export default LandingPage;
