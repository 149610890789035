import React from 'react';

import ItemCollectionNarrow from './ItemCollectionNarrow';
import ItemCollectionWide from './ItemCollectionWide';
import { useBreakpoint } from '@ingka/viewport-hooks';

import { GetListsQuery } from 'apis/list/reactQueryHooks';

type ItemCollectionProps = {
    items: GetListsQuery['lists'][number]['retail']['items'];
    onClick?: () => void;
    listId: string;
};

export default (props: ItemCollectionProps) => {
    const breakpoint = useBreakpoint('sm', 'md', 'lg', 'xl');
    return breakpoint === 'xl' ? (
        <ItemCollectionWide {...props} />
    ) : (
        <ItemCollectionNarrow {...props} />
    );
};
