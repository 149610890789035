import React, { useState } from 'react';

import styles from './AddList.module.scss';
import documentAdd from '@ingka/ssr-icon/paths/document-add';
import { useBreakpoint } from '@ingka/viewport-hooks';
import Button from '@skapa/Button';

import { useCreateList } from 'apis/list/operations';
import useGetLists from 'apis/list/useGetLists';

import { useSettings } from 'hooks/useSettings';
import useTranslations from 'hooks/useTranslations';

import NewListForm from 'components/Modals/NewListForm';

const AddList: React.FC<{ showSkeleton: boolean }> = ({ showSkeleton }) => {
    const breakpoint = useBreakpoint('sm', 'md', 'lg', 'xl');
    const { maxListNameLength, maxLists } = useSettings();
    const [addingList, setAddingList] = useState(false);
    const { translate, translateReplace } = useTranslations();
    const { lists } = useGetLists();
    const { createList } = useCreateList();
    const onCreateList = (name: string) => {
        const nameAlreadyExists = !!lists?.find(l => l.name === name);
        if (nameAlreadyExists) {
            return Promise.reject(translate('NAME_CONFLICT'));
        } else if (name === '') {
            return Promise.reject(translate('EMPTY_NAME'));
        } else if (name.length > Number(maxListNameLength)) {
            return Promise.reject(
                translateReplace('MAX_NAME_LENGTH', {
                    length: maxListNameLength?.toString(),
                })
            );
        }
        setAddingList(false);
        return createList({
            name,
        });
    };

    return (
        <>
            {addingList ? (
                <NewListForm
                    data-testid="new-list-form"
                    defaultValue={''}
                    onSubmit={newListName => onCreateList(newListName)}
                    onCancel={() => setAddingList(false)}
                />
            ) : (
                <Button
                    loading={showSkeleton}
                    style={{
                        ...(showSkeleton &&
                            breakpoint !== 'sm' && { width: 170 }),
                    }}
                    type="primary"
                    className={styles.create}
                    data-testid="create-new-list"
                    text={translate('Create list')}
                    iconPosition="leading"
                    ssrIcon={documentAdd}
                    disabled={lists && lists.length >= Number(maxLists)}
                    onClick={() => setAddingList(true)}
                />
            )}
        </>
    );
};
export default AddList;
