/* eslint no-unused-vars: 0 */
import React from 'react';

import styles from './ItemCollectionNarrow.module.scss';

import { GetListsQuery } from 'apis/list/reactQueryHooks';

import useTranslations from 'hooks/useTranslations';

import Link from 'components/Link';

type ItemCollectionProps = {
    items: GetListsQuery['lists'][number]['retail']['items'];
    onClick?: () => void;
};

enum Nums {
    n0,
    n1,
    n2,
    n3,
    n4,
}

const ItemCollectionNarrow = ({
    items,
    onClick,
    listId,
}: ItemCollectionProps & { listId: string }) => {
    const { translate } = useTranslations();
    return (
        <div className={styles.container}>
            <Link
                href={`${listId}/retail`}
                key={listId}
                data-testid={`${listId}-collection-content`}
            >
                <div
                    className={`${styles.content} ${
                        items.length === 0 ? styles.noItems : ''
                    } ${onClick ? styles.cursorPointer : ''}`}
                    onClick={onClick}
                >
                    {items.length === 0 && translate('No items in the list')}
                    {items.length !== 0 && (
                        <ItemCollectionContent items={items} />
                    )}
                </div>
            </Link>
        </div>
    );
};

const ItemCollectionContent = ({ items }: ItemCollectionProps) =>
    items.length <= Nums.n2 ? (
        <ItemLayout1plus1 items={items} />
    ) : (
        <ItemLayout1plus4 items={items} />
    );

const ItemLayout1plus1 = ({ items }: ItemCollectionProps) => (
    <>
        <ImageOrPlaceholder1
            full
            borderRadius="borderLeftRadius"
            item={items[Nums.n0]}
        />
        <ImageOrPlaceholder1
            full
            borderRadius="borderRightRadius"
            item={items[1]}
        />
    </>
);

const ItemLayout1plus4 = ({ items }: ItemCollectionProps) => (
    <>
        <ImageOrPlaceholder1
            full
            borderRadius="borderLeftRadius"
            item={items[Nums.n0]}
        />
        <div className={`${styles.container4} ${styles.borderRightRadius}`}>
            <ImageOrPlaceholder1 item={items[Nums.n1]} />
            <ImageOrPlaceholder1
                borderRadius="borderTopRightRadius"
                item={items[Nums.n2]}
            />
            <ImageOrPlaceholder1 item={items[3]} />
            <ImageOrPlaceholder1
                borderRadius="borderBottomRightRadius"
                item={items[Nums.n4]}
            />
        </div>
    </>
);

// If the item exists, return image. Otherwise return placeholder.
const ImageOrPlaceholder1 = ({
    borderRadius = '',
    item,
    full,
}: {
    borderRadius?:
        | ''
        | 'borderLeftRadius'
        | 'borderRightRadius'
        | 'borderTopRightRadius'
        | 'borderBottomRightRadius';
    item?: GetListsQuery['lists'][number]['retail']['items'][number];
    full?: boolean;
}) =>
    item?.product?.images?.[Nums.n2] && item?.product?.images?.[Nums.n3] ? (
        <img
            alt={item.product.name}
            className={`${styles.image} ${styles[borderRadius]}`}
            data-testid="item-collection-image"
            loading="lazy"
            src={item.product.images[full ? Nums.n3 : Nums.n2].url}
        />
    ) : (
        <div
            className={`${styles[borderRadius]}`}
            data-testid="item-collection-placeholder"
        />
    );

export default ItemCollectionNarrow;
