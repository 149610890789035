import React from 'react';

import styles from './FamilyPromoCard.module.scss';
import { H2, LI } from '@components/Skeleton';
import { useBreakpoint } from '@ingka/viewport-hooks';

import { logEvent } from 'utils/analytics/analytics';
import { setLocationHref } from 'utils/window';

import useAuth from 'hooks/useAuth2';
import { useSettings } from 'hooks/useSettings';
import useTranslations from 'hooks/useTranslations';

import Button from 'skapa/Button';
import List from 'skapa/List';

type FamilyPromoCardProps = {
    showSkeleton: boolean;
};

const FamilyPromoCard: React.FC<FamilyPromoCardProps> = ({ showSkeleton }) => {
    const { familySignupUrl, familyUpgradeUrl, loginUrl } = useSettings();
    const breakpoint = useBreakpoint('sm', 'md', 'lg', 'xl');
    const { translate } = useTranslations();
    const { isLoggedIn, isFamilyMember, customerType } = useAuth();

    const handleJoinFamily = () => {
        logEvent({
            category: 'favourites',
            action: 'promote_family_click',
            label: 'join',
        });
        if (isLoggedIn) {
            setLocationHref(familyUpgradeUrl);
        } else {
            setLocationHref(familySignupUrl);
        }
    };

    if (isFamilyMember || customerType === 'business') {
        return null;
    }

    return (
        <div data-testid="family-promo-card" className={styles.container}>
            <H2
                showSkeleton={showSkeleton}
                width={breakpoint === 'sm' ? '80%' : '50%'}
            >
                {translate('PROMOTE_FAMILY_TITLE')}
            </H2>

            <List
                className={styles.list}
                style={{ ...(showSkeleton && { paddingLeft: 0 }) }}
            >
                <LI
                    showSkeleton={showSkeleton}
                    width={breakpoint === 'sm' ? '60%' : '30%'}
                    style={{ listStyleType: 'none' }}
                >
                    {translate('PROMOTE_FAMILY_BULLET1')}
                </LI>
                <LI
                    showSkeleton={showSkeleton}
                    width={breakpoint === 'sm' ? '60%' : '30%'}
                    style={{ listStyleType: 'none' }}
                >
                    {translate('PROMOTE_FAMILY_BULLET2')}
                </LI>
            </List>
            <div className={styles.buttons}>
                <Button
                    loading={showSkeleton}
                    style={{
                        ...(showSkeleton &&
                            breakpoint !== 'sm' && { width: 170 }),
                    }}
                    type="primary"
                    inverseTheme
                    small={breakpoint === 'sm'}
                    text={translate('PROMOTE_FAMILY_BUTTON')}
                    onClick={handleJoinFamily}
                />

                {!isLoggedIn && (
                    <Button
                        loading={showSkeleton}
                        style={{
                            ...(showSkeleton &&
                                breakpoint !== 'sm' && { width: 170 }),
                        }}
                        type="secondary"
                        inverseTheme
                        small={breakpoint === 'sm'}
                        text={translate('Log in')}
                        onClick={() => {
                            logEvent({
                                category: 'favourites',
                                action: 'promote_family_click',
                                label: 'login',
                            });
                            setLocationHref(loginUrl);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default FamilyPromoCard;
