import { useEffect } from 'react';

import { GetInspirationsQuery } from '../reactQueryHooks';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

export const useInspirationSubscriptions = (
    data: GetInspirationsQuery | undefined,
    reload: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<GetInspirationsQuery>>
) => {
    useEffect(() => {
        window?.ikea?.pubsub?.subscribe(
            'FAVOURITE_CLIENT/ADD_INSPIRATION_SUCCESS',
            reload
        );
        window?.ikea?.pubsub?.subscribe(
            'FAVOURITE_CLIENT/REMOVE_INSPIRATION_SUCCESS',
            reload
        );
        return () => {
            window?.ikea?.pubsub?.unsubscribe(
                'FAVOURITE_CLIENT/ADD_INSPIRATION_SUCCESS',
                reload
            );
            window?.ikea?.pubsub?.unsubscribe(
                'FAVOURITE_CLIENT/REMOVE_INSPIRATION_SUCCESS',
                reload
            );
        };
    }, []);

    useEffect(() => {
        if (data) {
            window?.ikea?.pubsub?.publish('TROJAN_AGENT/UPDATE_DATA', {
                data: data?.inspirations,
                source: 'inspiration',
                store: 'favourites',
            });
        }
    }, [data]);
};
