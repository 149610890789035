import React from 'react';

import { Fragment, P } from '@components/Skeleton';

const SelectListItemSkeleton = () => (
    <>
        <P showSkeleton width={100} />
        <P showSkeleton width={70} style={{ marginBottom: 10 }} />
        <Fragment showSkeleton height={262} />
    </>
);

export default SelectListItemSkeleton;
