import React from 'react';

import { prefetchTranslations } from '@hooks/useTranslations';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetServerSideProps } from 'next';

import Grid from 'skapa/Grid';

import LandingContainer from 'components/LandingContainer';
import LandingPage from 'components/LandingPage';

const LandingPageWrapper: React.FunctionComponent = () => {
    return (
        <Grid>
            <LandingContainer>
                <LandingPage />
            </LandingContainer>
        </Grid>
    );
};

export const getServerSideProps: GetServerSideProps = async ctx => {
    const queryClient = new QueryClient();
    await Promise.all([prefetchTranslations(ctx, queryClient)]);
    return {
        props: {
            dehydratedState: dehydrate(queryClient),
        },
    };
};

export default LandingPageWrapper;
