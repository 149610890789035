import React, { useState } from 'react';

import styles from './NewListForm.module.scss';
import checkmark from '@ingka/ssr-icon/paths/checkmark';
import cross from '@ingka/ssr-icon/paths/cross';

import { useSettings } from 'hooks/useSettings';
import useTranslations from 'hooks/useTranslations';

import Button from 'skapa/Button';
import FormField from 'skapa/FormField';
import InputField from 'skapa/InputField';

type NewListFormProps = {
    onCancel: () => void;
    onSubmit: (value: string) => Promise<any>;
    defaultValue: string;
    className?: string;
};

const NewListForm: React.FC<NewListFormProps> = ({
    defaultValue,
    onCancel,
    onSubmit,
    className = '',
}) => {
    const { maxListNameLength } = useSettings();
    const { translate, translateReplace } = useTranslations();
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [inputValue, setInputValue] = useState(defaultValue);

    return (
        <form
            className={`${className} ${styles.form}`}
            data-testid="new-list-form"
            onSubmit={event => {
                event.preventDefault();
                if (inputValue) {
                    setLoading(true);
                    onSubmit(inputValue).catch(e => {
                        setValidation(true);
                        setValidationMessage(e);
                    });
                    setLoading(false);
                } else {
                    setValidation(true);
                    setValidationMessage(translate('EMPTY_NAME'));
                }
            }}
            onReset={e => {
                e.preventDefault();
                onCancel();
            }}
        >
            <FormField
                shouldValidate={validation}
                className={styles.formField}
                validation={{
                    msg: validationMessage,
                }}
                characterLimit={Number(maxListNameLength)}
                fieldHelper={{
                    msg: translateReplace('Input field help message', {
                        length: maxListNameLength?.toString(),
                    }),
                }}
            >
                <InputField
                    type="text"
                    id="new-list-form-input"
                    data-testid="new-list-form-input"
                    autoFocus
                    disabled={loading}
                    value={inputValue}
                    className={styles.inputField}
                    onChange={event => setInputValue(event.target.value)}
                />
            </FormField>
            <div className={styles.buttonContainer}>
                <Button
                    htmlType="submit"
                    aria-label={translate('Create list')}
                    iconOnly
                    type="tertiary"
                    disabled={loading}
                    ssrIcon={checkmark}
                />
                <Button
                    htmlType="reset"
                    aria-label={translate('Undo')}
                    className="edit"
                    iconOnly
                    type="tertiary"
                    disabled={loading}
                    ssrIcon={cross}
                />
            </div>
        </form>
    );
};
export default NewListForm;
