import React from 'react';

import styles from './EmptyLandingPage.module.scss';
import { H1, P } from '@components/Skeleton';

import useTranslations from 'hooks/useTranslations';

import AddList from 'components/AddList';
import FamilyPromoCard from 'components/FamilyPromoCard';
import Feeds from 'components/Feeds';

type EmptyLandingPageProps = {
    showSkeleton: boolean;
};

const EmptyLandingPage: React.FC<EmptyLandingPageProps> = ({
    showSkeleton,
}) => {
    const { translate } = useTranslations();
    return (
        <div data-testid="empty-landing-page">
            <header>
                <H1
                    showSkeleton={showSkeleton}
                    width={270}
                    className={styles.title}
                >
                    {translate('Empty landing page title')}
                </H1>
            </header>
            <div className={styles.body}>
                <P showSkeleton={showSkeleton}>
                    {translate('Empty landing page body')}
                </P>
                <AddList showSkeleton={showSkeleton} />
            </div>
            <FamilyPromoCard showSkeleton={showSkeleton} />
            <Feeds />
        </div>
    );
};
export default EmptyLandingPage;
