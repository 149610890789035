import { useGetInspirationsQuery } from '../reactQueryHooks';
import { useInspirationSubscriptions } from './useInspirationSubscriptions';
import useAuth from '@hooks/useAuth2';
import { captureException } from '@sentry/react';

import useLocale from 'hooks/useLocale';

const useGetInspirations = () => {
    const { accessToken } = useAuth();
    const { language } = useLocale();

    const { data, isLoading, refetch, isFetched } = useGetInspirationsQuery(
        {
            languageCode: language,
        },
        {
            enabled: Boolean(accessToken),
            placeholderData: { inspirations: [] },
            onError: err => captureException(err),
        }
    );

    // Subscribes to pubsub
    useInspirationSubscriptions(data, refetch);

    return {
        isLoading,
        inspirations: data?.inspirations,
        isFetched,
    };
};

export default useGetInspirations;
