
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[cc]/[ll]/favourites",
      function () {
        return require("private-next-pages/[cc]/[ll]/favourites/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[cc]/[ll]/favourites"])
      });
    }
  