import React from 'react';

import styles from './SelectListItem.module.scss';
import ellipsis from '@ingka/ssr-icon/paths/ellipsis';

import { GetListsQuery } from 'apis/list/reactQueryHooks';

import useTranslations from 'hooks/useTranslations';

import Button from 'skapa/Button';

import ItemCollection from 'components/ItemCollection';
import ListHeader from 'components/ListHeader';

type SelectListItemProps = {
    list: GetListsQuery['lists'][number];
    onSelect: (listId: string) => Promise<any>;
    isPendingRemoval: boolean;
    openMenu: () => void;
};
const SelectListItem: React.FC<SelectListItemProps> = ({
    list: l,
    onSelect,
    isPendingRemoval,
    openMenu,
}) => {
    const { translate } = useTranslations();
    return (
        <div
            className={`${styles.container} ${
                isPendingRemoval ? styles.hidden : ''
            }`}
            data-testid="select-list-item"
        >
            <ListHeader
                list={l}
                onClick={onSelect}
                itemsCount={l.retail.items.length}
            >
                <Button
                    small
                    onClick={openMenu}
                    iconOnly
                    type="tertiary"
                    ssrIcon={ellipsis}
                    aria-label={translate('Open context menu for list')}
                    data-testid={`list-menu-${l.listId}`}
                />
            </ListHeader>
            <ItemCollection
                items={l.retail.items}
                onClick={() => onSelect(l.listId)}
                listId={l.listId}
            />
        </div>
    );
};

export default SelectListItem;
