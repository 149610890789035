/* eslint no-unused-vars: 0 */
import React from 'react';

import styles from './ItemCollectionWide.module.scss';

import { GetListsQuery } from 'apis/list/reactQueryHooks';

import useTranslations from 'hooks/useTranslations';

import Link from 'components/Link';

type ItemCollectionProps = {
    items: GetListsQuery['lists'][number]['retail']['items'];
    onClick?: () => void;
};

enum Nums {
    n0,
    n1,
    n2,
    n3,
    n4,
}

const ItemCollectionWide = ({
    items,
    onClick,
    listId,
}: ItemCollectionProps & { listId: string }) => {
    const { translate } = useTranslations();
    return (
        <div className={styles.container}>
            <Link
                href={`${listId}/retail`}
                data-testid={`${listId}-collection-content`}
            >
                <div
                    className={`${styles.content} ${
                        items.length === 0 ? styles.noItems : ''
                    } ${onClick ? styles.cursorPointer : ''}`}
                    onClick={onClick}
                >
                    {items.length === 0 && translate('No items in the list')}
                    {items.length !== 0 &&
                        items?.map((item, i) =>
                            i < 5 ? (
                                <ImageOrPlaceholder1 key={i} item={item} />
                            ) : null
                        )}
                </div>
            </Link>
        </div>
    );
};

// If the item exists, return image. Otherwise return placeholder.
const ImageOrPlaceholder1 = ({
    item,
}: {
    item: GetListsQuery['lists'][number]['retail']['items'][number];
}) =>
    item?.product?.images?.[Nums.n2] ? (
        <img
            alt={item.product.name}
            className={styles.image}
            data-testid="item-collection-image"
            loading="lazy"
            src={item.product.images[Nums.n2].url}
        />
    ) : (
        <div data-testid="item-collection-placeholder" />
    );

export default ItemCollectionWide;
