import React, { ReactNode } from 'react';

import styles from './ListHeader.module.scss';

import { GetListsQuery } from 'apis/list/reactQueryHooks';

import useGetListName from 'hooks/useGetListName';
import useTranslations from 'hooks/useTranslations';

import Link from 'components/Link';

type ListHeaderProps = {
    children?: ReactNode;
    list: GetListsQuery['lists'][number];
    onClick: (listId: string) => void;
    itemsCount: number;
};

const ListHeader: React.FC<ListHeaderProps> = ({
    children,
    list,
    onClick,
    itemsCount,
}: ListHeaderProps) => {
    const { translateReplace } = useTranslations();
    const getListName = useGetListName();
    const name = getListName(list);
    return (
        <header className={styles.header}>
            <div className={styles.listNameContent}>
                <div className={styles.headingContainer}>
                    <Link href={`${list.listId}/retail`}>
                        <div
                            className={styles.listName}
                            onClick={() => onClick(list.listId)}
                        >
                            {name}
                        </div>
                    </Link>{' '}
                </div>
                <div className={styles.listItemCount}>
                    {translateReplace('x items', {
                        quantity: String(itemsCount),
                    })}
                </div>
            </div>
            <div>{children}</div>
        </header>
    );
};

export default ListHeader;
